<template>
  <div>
    <v-autocomplete
      outlined
      :items="addresses"
      label="Adres zlecenia"
      v-model="addressId"
      item-text="formattedAddress"
      item-value="id"
      placeholder="-"
      append-icon="mdi-chevron-down"
      dense
      :disabled="showAddressForm"
      @change="val => $emit('update', { addressId: val })"
    />
    <v-btn
      v-if="!showAddressForm"
      color="primary"
      text
      small
      :disabled="!addressId"
      @click="changeAddressFormStatus(true)"
    >
      Edytuj lokalizacje
    </v-btn>
    <div v-if="showAddressForm">
      <v-divider class="py-3" />
      <AddressForm
        ref="addressForm"
        actions
        :editing-address="selectedAddress"
        :forced-department="department.id"
        @close="changeAddressFormStatus(false)"
        @editAddress="updateAddress"
      />
    </div>
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import AddressForm from '../Partials/AddressForm'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [inputMixin],

  components: {
    AddressForm
  },

  data() {
    return {
      showAddressForm: false,
      addressId: null
    }
  },

  created() {
    this.fetchClientAddresses().then(() => {
      const orderAddress = this.addresses.find(address => {
        return address.id === this.data.address.id
      })
      this.addressId = orderAddress ? orderAddress.id : null
    })
  },

  computed: {
    ...mapState({
      department: state => state.core.department,
      addresses: state => state.addresses.items
    }),
    selectedAddress() {
      return this.addresses.find(address => address.id === this.addressId)
    }
  },
  methods: {
    ...mapActions({
      getAddresses: 'addresses/getItems',
      editAddress: 'addresses/editItem'
    }),
    fetchClientAddresses() {
      return this.getAddresses({
        params: { filters: { clientId: this.data.client.id, departmentIds: [this.department.id] } }
      })
    },
    changeAddressFormStatus(status) {
      this.showAddressForm = status
    },
    updateAddress(address) {
      const params = {
        tableName: 'addresses',
        params: address,
        id: this.addressId
      }
      this.editAddress(params).then(() => {
        this.fetchClientAddresses()
        this.changeAddressFormStatus(false)
        this.$emit('update', { addressId: this.addressId })
      })
    }
  }
}
</script>
